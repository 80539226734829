<template>
    <div>
       <div class="user">
           <h2 style="text-align:center">客户报表</h2>
            <div class="title" style=" margin-bottom:20px">
                <a-radio-group v-model="userBtn" @change="handleCustomerSelectedChange" style="margin-right:20px">
                    <a-radio-button value="day">
                        日
                    </a-radio-button>
                    <a-radio-button value="month">
                        月
                    </a-radio-button>
                    <a-radio-button value="quarter">
                        季度
                    </a-radio-button>
                    <a-radio-button value="year">
                        年
                    </a-radio-button>
                </a-radio-group>
                <span  v-if="userBtn=='day'">
                     <a-date-picker
                        v-model="startDate"
                        :disabled-date="disabledDate"
                        placeholder="开始日期"
                        @change="handleStartOpenChange"
                    />
                    -
                    <a-date-picker
                        v-model="endDate"
                        :disabled-date="disabledEndDate"
                        placeholder="结束日期"
                        :disabled="endOpen"
                    />
                </span>
                <span v-if="userBtn=='month'">
                    <a-month-picker placeholder="开始月份" :disabled-date="disabledStartMonth"  v-model="startMonth" @change="startMonthChange" />
                     - 
                    <a-month-picker placeholder="结束月份" :disabled-date="disabledEndMonth" @change="monthChange" :disabled="showEndMonth" v-model="endMonth"/>
                </span>
                <a-select v-if="userBtn=='year'" placeholder="请选择年份" style="width: 200px" v-model="selectYear">
                    <a-select-option v-for="i in yearList" :key="i" :value="i">
                        {{i}}年
                    </a-select-option>
                </a-select>
                <span v-if="userBtn=='quarter'">
                    <a-select style="width: 120px" placeholder="请选择年份"  v-model="selectYear">
                        <a-select-option v-for="i in yearList" :key="i" :value="i">
                            {{i}}年
                        </a-select-option>
                    </a-select>
                    <a-select style="width:120px;margin-left:5px" placeholder="请选择季度" :disabled="!this.selectYear" v-model="selectQuarter">
                        <a-select-option v-for="i in 4" :key="i" :value="i">
                            第{{i}}季度
                        </a-select-option>
                    </a-select>
                </span>
                <a-button style="margin-left:20px" type="primary" @click="handleSearch">查询</a-button>
            </div>
        </div>
         <!-- <div class="user">
            <div id="ratio"></div>
             <a-select default-value="cx" class="choose" @change="handleChange">
                <a-select-option value="zq">
                    中秋活动
                </a-select-option>
                <a-select-option value="gq">
                    国庆优惠
                </a-select-option>
                <a-select-option value="yr">
                    双十一预热
                </a-select-option>
                <a-select-option value="cx">
                    双十一大促销
                </a-select-option>
            </a-select>
        </div> -->
        <div class="user">
            <div id="member"></div>
        </div> 
        <div class="user">
            <div id="age"></div>
        </div>
        <div class="flex-user">
            
            <div class="view">
                <div  class="title">
                    <a-range-picker
                        :disabled-date="disabledDate"
                        v-model="userDate"
                        size="small"
                        style="width:220px"
                        @change="onUserDateChange"
                    />
                </div>
                <div id="user-word"></div>
            </div>
            <div class="view">
                <div class="title">
                    <a-range-picker
                        :disabled-date="disabledDate"
                        v-model="wordDate"
                        size="small"
                        style="width:220px"
                        @change="onWordDateChange"
                    />
                </div>
                <div id="word"></div>
            </div>
            
        </div>
    </div>
</template>
<script>
import * as echarts from 'echarts';
import moment from 'moment';
export default {
    name:'dash',
    data(){
        return{
            userBtn:'day',
            wordBtn:'word',
            startDate:undefined,
            endDate:undefined,
            startMonth:undefined,
            endMonth:undefined,
            selectYear:undefined,
            selectQuarter:undefined,
            endOpen:true,
            showEndMonth:true,
            wordDate:undefined,
            userDate:undefined,
            startWord:undefined,
            endWord:undefined,
            startUser:undefined,
            endUser:undefined,
            option:{
                title: {
                    text: '用户统计',
                    left: 'center',
                },
                xAxis: {
                    type: 'category',
                    data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
                },
                yAxis: {
                    type: 'value'
                },
                series: [
                    {
                    data: [15000, 23000, 22400, 21800, 13500, 14700, 26000],
                    type: 'line'
                    }
                ]
            },
            userOption: {
                toolbox: {
                    show: true,
                    feature: {
                        saveAsImage: {
                    　　　　show:true,
                    　　　　excludeComponents :['toolbox'],
                    　　　　pixelRatio: 1 
                        }
                    }
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                    type: 'shadow'
                    }
                },
                legend: {
                    data: ['新增','流失','差值']
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: [
                    {
                    type: 'value'
                    }
                ],
                yAxis: [
                    {
                        type: 'category',
                        axisTick: {
                            show: false
                        },
                        data: []
                    }
                ],
                series: [
                    {
                    name: '新增',
                    type: 'bar',
                    stack: 'Total',
                    color:'#93CB79',
                    label: {
                        show: true,
                        formatter: function (params) {
                            if (params.value !== 0) {
                            return params.value;
                            } else {
                            return '';
                            }
                        },
                    },
                    emphasis: {
                        focus: 'series'
                    },
                    data: []
                    },
                    {
                    name: '流失',
                    type: 'bar',
                    stack: 'Total',
                    color:'#FD7173',
                    label: {
                        show: true,
                        position: 'left',
                        formatter: function (params) {
                            if (params.value !== 0) {
                            return params.value;
                            } else {
                            return '';
                            }
                        },
                    },
                    emphasis: {
                        focus: 'series'
                    },
                    data: []
                    },
                    {
                    name: '差值',
                    type: 'bar',
                    color:'#F5DB83',
                    label: {
                        show: true,
                        position: 'inside',
                        formatter: function (params) {
                            if (params.value !== 0) {
                            return params.value;
                            } else {
                            return '';
                            }
                        },
                    },
                    emphasis: {
                        focus: 'series'
                    },
                    data: []
                    },
                    
                ]
            },
            genderOption:{
                title: {
                    text: '用户性别',
                    left: 'center'
                },
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    orient: 'vertical',
                    left: 'left'
                },
                series: [
                    {
                    name: '用户占比',
                    type: 'pie',
                    radius: '50%',
                    data: [
                        { value: 1048, name: '女性' },
                        { value: 735, name: '男性' },
                        { value: 580, name: '未知' }
                    ],
                    emphasis: {
                        itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                        }
                    }
                    }
                ]
            },
            ageOption:{
                toolbox: {
                    show: true,
                    feature: {
                        saveAsImage: {
                    　　　　show:true,
                    　　　　excludeComponents :['toolbox'],
                    　　　　pixelRatio: 1 
                        }
                    }
                },
                title: {
                    text: '拓客信息统计',
                    left: 'center'
                },
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    orient: 'vertical',
                    left: 'left'
                },
                series: [
                    {
                    name: '结果',
                    type: 'pie',
                    radius: '50%',
                    center: ['50%', '50%'],
                    label: {
                        formatter: '{b}：{c}  {d}%',
                    
                    },
                    data: [
                        { value: 104800, name: '未开始' },
                        { value: 73500, name: '已发送' },
                        { value: 58000, name: '已接收' },
                        { value: 20800, name: '未找到' }
                    ],
                    emphasis: {
                        itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                        }
                    }
                    }
                ]
            },
            ratioOption:{
                title: {
                    text: '转化率',
                    left: 'center'
                },
                tooltip: {
                    trigger: 'item',
                    formatter: '{a} <br/>{b} : {c}%'
                },
                series: [
                    {
                        name: '转化率',
                        type: 'funnel',
                        left: '10%',
                        top: 60,
                        bottom: 60,
                        width: '80%',
                        min: 0,
                        max: 100,
                        minSize: '0%',
                        maxSize: '100%',
                        sort: 'descending',
                        gap: 2,
                        label: {
                            show: true,
                            position: 'inside'
                        },
                        labelLine: {
                            length: 10,
                            lineStyle: {
                            width: 1,
                            type: 'solid'
                            }
                        },
                        itemStyle: {
                            borderColor: '#fff',
                            borderWidth: 1
                        },
                        emphasis: {
                            label: {
                            fontSize: 20
                            }
                        },
                        data: [
                            { value: 40, name: '留资' },
                            { value: 20, name: '订单' },
                            { value: 80, name: '扫码' },
                            { value: 100, name: '展现' }
                        ]
                    }
                ]
            },
            wordOption: {
                toolbox: {
                    show: true,
                    feature: {
                        saveAsImage: {
                    　　　　show:true,
                    　　　　excludeComponents :['toolbox'],
                    　　　　pixelRatio: 1 
                        }
                    }
                },
                title: {
                    text: '敏感词Top(关键字)',
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                    type: 'shadow'
                    }
                },
                legend: {},
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: {
                    type: 'value',
                    boundaryGap: [0, 0.01]
                },
                yAxis: {
                    type: 'category',
                    data: []
                },
                series: [
                    {
                    name: '',
                    type: 'bar',
                    data: []
                    }
                ]
            },
            userWordOption: {
                toolbox: {
                    show: true,
                    feature: {
                        saveAsImage: {
                    　　　　show:true,
                    　　　　excludeComponents :['toolbox'],
                    　　　　pixelRatio: 1 
                        }
                    }
                },
                title: {
                    text: '敏感词Top(用户)',
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                    type: 'shadow'
                    }
                },
                legend: {},
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: {
                    type: 'value',
                    boundaryGap: [0, 0.01]
                },
                yAxis: {
                    type: 'category',
                    data: []
                },
                series: [
                    {
                    name: '',
                    type: 'bar',
                    data: []
                    }
                ]
            },
            ratioChart:null,
            userChart:null,
            wordChart:null,
            userwordChart:null,
            yearList:[],
            wordList:[],
            userList:[]
        }
    },
    mounted(){
        this.initData()
        this.init()
        let current = moment().year()
        let before = current - 5
        for(let i = before,k=current;i<=k; i++){
            this.yearList.push(i)
        }
        
    },
    methods:{
        moment,
        disabledDate(current) {
            return current && current > moment().startOf('day');
        },
        disabledEndDate(current){
            return current && (current > moment().startOf('day') || current > moment(this.startDate).add(7,'days') || current < moment(this.startDate) );
        },
        disabledStartMonth(current){
            return current && current > moment().endOf('day');
        },
        disabledEndMonth(current){
            return current && current > moment().endOf('day');
        },
        onWordDateChange(date,dateString){
            this.startWord= new Date(dateString[0]).getTime()
            this.endWord= new Date(dateString[1]).getTime()
            this.getWordData()
        },
        onUserDateChange(date,dateString){
            this.startUser= new Date(dateString[0]).getTime()
            this.endUser= new Date(dateString[1]).getTime()
            this.getUserWordData()
        },
        monthChange(date){
            let t = moment(date)
            if(this.startMonth>this.endMonth){
                this.$message.error('结束月份不能大于开始月份')
                this.endMonth = undefined
                return
            }
            if(this.startMonth<t.subtract(7,'months')){
                this.$message.error('最多选择七个月份的数据')
                this.endMonth = undefined
                return
            }
        },
        startMonthChange(){
            if(this.startMonth){
                this.showEndMonth = false
            }
        },
        
        handleStartOpenChange(){
            if(this.startDate){
                this.endOpen = false
            }
        },
        handleCustomerSelectedChange(){
           this.startDate =undefined
            this.endDate =undefined
            this.startMonth =undefined
            this.endMonth =undefined
            this.selectYear=undefined
            this.selectQuarter =undefined
            this.endOpen =true
            this.showEndMonth = true
        },
        handleChange(val){
          let arr = []
            switch(val){
                case 'zq':
                    arr = 
                        [
                            { value: 60, name: '留资' },
                            { value: 40, name: '订单' },
                            { value: 80, name: '扫码' },
                            { value: 100, name: '展现' }
                        ]
                    this.$set(this.ratioOption.series[0],'data',arr)
                    break;
                case 'gq':
                    arr = 
                        [
                            { value: 70, name: '留资' },
                            { value: 50, name: '订单' },
                            { value: 90, name: '扫码' },
                            { value: 100, name: '展现' }
                        ]
                    this.$set(this.ratioOption.series[0],'data',arr)
                    break;
                case 'yr':
                    arr = 
                        [
                            { value: 40, name: '留资' },
                            { value: 30, name: '订单' },
                            { value: 50, name: '扫码' },
                            { value: 90, name: '展现' }
                        ]
                    this.$set(this.ratioOption.series[0],'data',arr)
                    break;
                case 'cx':
                    arr = [
                            { value: 40, name: '留资' },
                            { value: 20, name: '订单' },
                            { value: 80, name: '扫码' },
                            { value: 100, name: '展现' }
                        ]
                    this.$set(this.ratioOption.series[0],'data',arr)
                    break;
            }
            this.ratioChart.setOption(this.ratioOption);
        },
        handleSearch(){
           this.getUserData()
        },
        init(){
            // let chartDom = document.getElementById('line');
            // this.userChart = echarts.init(chartDom);
            // this.userChart.setOption(this.option);

            let userDom = document.getElementById('member');
            this.userChart = echarts.init(userDom);
            this.userChart.setOption(this.userOption);

            let wordDom = document.getElementById('word');
            this.wordChart = echarts.init(wordDom);
            this.wordChart.setOption(this.wordOption);

            let userwordDom = document.getElementById('user-word');
            this.userwordChart = echarts.init(userwordDom);
            this.userwordChart.setOption(this.userWordOption);

            


            // let ratioDom = document.getElementById('ratio');
            // this.ratioChart = echarts.init(ratioDom);
            // this.ratioChart.setOption(this.ratioOption);
        },
        initData(){
            
            // let lineArr =  this.get30days()
            // this.getUserData(lineArr)
            this.getExpandData()
            this.userBtn = "day"
            this.endDate = moment().subtract(1,'days')
            this.startDate = moment().subtract(7,'days')
            this.handleSearch()
            this.getWordData()
            this.getUserWordData()
        },
        getExpandData(){
            let topic = 'scrm/wxwork/getExpandData'
            let payload = {
                msgId:'getExpandData',
                data: {
                },
            };
            
            this.$mqtt.doPublish(
                {
                pubTopic: topic,
                payload,
                },
                (topic, data) => {
                    let val = []
                    val.push({
                        value: data.ready_count, name: '未开始' 
                    })
                    val.push({
                        value:data.send_count,name:'已发送'
                    })
                    val.push({value:data.received_count,name:'已接收'})
                    val.push({value:data.unfound_count,name:'未找到'})
                    this.ageOption.series[0].data = val
                    let ageDom = document.getElementById('age');
                    const ageChart = echarts.init(ageDom);
                    ageChart.setOption(this.ageOption);
                    
                },
                this
            );
        },
        getUserData(){
            if(this.userBtn==="day"){
                if(!this.endDate){
                    this.$message.error('请选择结束日期')
                    return
                }
            }else if(this.userBtn ==='month'){
                if(!this.endMonth){
                    this.$message.error('请选择结束月份')
                    return
                }
            }else if(this.userBtn==='quarter'){
                if(!this.selectQuarter){
                    this.$message.error('请选择要查询的季度')
                    return
                }
            }else if(this.userBtn === 'year'){
                if(!this.selectYear){
                    this.$message.error('请选择要查询的年份')
                    return
                }
            }
            let topic = 'scrm/wxwork/getUserData'
            let payload = {
                msgId:'searchUserData',
                data: {
                    type:this.userBtn
                },
            };
            if(this.userBtn ==='year'){
                payload.data.year = this.formatDate()
            }else{
                payload.data.split_time = this.formatDate()
            }

            this.$mqtt.doPublish(
                {
                pubTopic: topic,
                payload,
                },
                (topic, data) => {
                    if(data.success){
                        if(this.userBtn == 'year'){
                            this.userOption.yAxis[0].data = [this.formatDate()]
                        }else{
                            this.userOption.yAxis[0].data = this.formatDate()
                        }
                       this.userOption.series[0].data = data.res.map(item=>item.new_contact_cnt)
                       this.userOption.series[1].data = data.res.map(item=> 0-item.negative_feedback_cnt)
                       this.userOption.series[2].data = data.res.map(item=> Math.abs(item.new_contact_cnt-item.negative_feedback_cnt))
                       this.userChart.setOption(this.userOption);
                    }
                },
                this
            );
        },
        getUserWordData(){
            let topic = 'scrm/risk/report'
            let payload = {
                msgId:'userWordReport',
                data: {
                   dim:'user_id'
                },
            };
            
            if(this.endUser){
                payload.data.begin_time = this.startUser
                payload.data.end_time = this.endUser
            }
            this.$mqtt.doPublish(
                {
                pubTopic: topic,
                payload,
                },
                (topic, data) => {
                   this.userList = data.res.reverse()
                   if(data.res.length>0){
                       this.userWordOption.yAxis.data = this.userList.map(item=>item._id.user_id)
                       this.userWordOption.series[0].data = this.userList.map(item=>item.count)
                       this.userwordChart.setOption(this.userWordOption);
                   }
                },
                this
            );
        },
        getWordData(){
            let topic = 'scrm/risk/report'
            let payload = {
                msgId:'wordReport',
                data: {
                   dim:'word'
                },
            };
            
            if(this.endWord){
                payload.data.begin_time = this.startWord
                payload.data.end_time = this.endWord
            }
            this.$mqtt.doPublish(
                {
                pubTopic: topic,
                payload,
                },
                (topic, data) => {
                   this.wordList = data.res.reverse()
                   if(data.res.length>0){
                       this.wordOption.yAxis.data = this.wordList.map(item=>item._id.word)
                       this.wordOption.series[0].data = this.wordList.map(item=>item.count)
                       this.wordChart.setOption(this.wordOption);
                   }
                },
                this
            );
        },
        formatDate(){
            if(this.userBtn == 'day'){
               if(this.startDate.format('YYYY-MM-DD') == this.endDate.format('YYYY-MM-DD')){
                   return [this.startDate.format('YYYY-MM-DD')]
               }
               let arr = []
               let diff = moment(this.endDate).diff(moment(this.startDate),'days')
                for(let i = 0,len=diff;i<=len;i++){
                    arr.push(moment(this.startDate).add(i,'days').format('YYYY-MM-DD'))
                }
                return arr
            }else if(this.userBtn =='month'){
                if(this.startMonth.format('YYYY-MM') == this.endMonth.format('YYYY-MM')){
                   return [this.startMonth.format('YYYY-MM')]
               }
               let arr = []
               let diff = moment(this.endMonth).diff(moment(this.startMonth),'months')
                for(let i = 0,len=diff;i<=len;i++){
                    arr.push(moment(this.startMonth).add(i,'months').format('YYYY-MM'))
                }
                return arr

            }else if(this.userBtn=='quarter'){
                let months = []
                let arr = []
                this.selectQuarter===1 && (months = ['01'])
                this.selectQuarter===2 && (months = ['02'])
                this.selectQuarter===3 && (months = ['03'])
                this.selectQuarter===4 && (months = ['04'])
                for(let i = 0,len= months.length;i<len;i++){
                    arr.push(this.selectYear+'-'+months[i])
                }
                return arr
            }else if(this.userBtn == 'year'){
                return this.selectYear
            }
        },
        
    }
}
</script>
<style lang="scss" scoped>
#user-word,#word{
    width: 100%;
    height: 300px;
}
#member,#age{
    width:100%;
    height: 500px;
    margin-bottom: 50px;
}
.flex-user{
    display: flex;
    align-items: cneter;
    justify-content: space-between;
    position: relative;
    .view{
        width:45%;
        position: relative;
        .title{
            position: absolute;
            z-index: 10;
            top:0px;
            right: 30px;
        }
    }
}
.user{
    width: 100%;
    // text-align: center;
    position: relative;
    .choose{
        width:130px;
        position: absolute;
        z-index: 9999;
        top: 10px;
        right: 10px;
    }
}
</style>